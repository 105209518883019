import { useEffect, useState } from "react";
import { Container, Table, Message, Tab, Flag, Segment, List } from "semantic-ui-react";
import DynastyAPI from "../api/dynasty";
import Util from "../util";
import Loading from "../components/Main/Loading";
import DynastyCard from "../components/Main/DynastyCard";
import LeagueCenter from "../components/Main/LeagueCenter";
import LeagueCard from "../components/Main/LeagueCard";
import Constants from "../constants/constants";

const DynastyDetail = () => {
  const [dynasty, setDynasty] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [activeLeagues, setActiveLeagues] = useState([]);
  const [pastWinners, setPastWinners] = useState([]);

  useEffect(() => {
    async function getDynasty(id) {
      try {
        const response = await DynastyAPI.getDynastyInfo(id);
        if (response.success) {
          setDynasty(response.data.dynasty);
          setActiveLeagues(response.data.dynasty.activeLeagues);
          setPastWinners(response.data.dynasty.pastWinners);
          setLoading(false);
        } else {
          Util.handleError(response.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        Util.handleError(error, "Failed to load dynasty, please reload");
      }
    }

    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");

    if (id) {
      getDynasty(id);
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      document.title = dynasty?.name || "Dynasty Details";
    }
  }, [dynasty]);

   // Number of members format
   const membersFormatter = new Intl.NumberFormat('en-GB', {
    notation: 'compact', compactDisplay: 'short'
  });

  const panes = [
    {
      menuItem: "Active Leagues",
      render: () => (
        <Tab.Pane key="activeLeagues" className="no-padding no-border">
          {activeLeagues.length ? (
            <Segment basic className="reduced-padding no-margin">
              <List relaxed>
                {activeLeagues.map((league) => (
                    <List.Item key={league._id} style={{ paddingBottom: '5px' }}>
                      <LeagueCard league={league} membersFormatter={membersFormatter} userNotInLeague={!league.isUserInLeague} />
                    </List.Item>
                  ))
                }
              </List>
            </Segment>
          ) : (
            <Message info content="No active leagues found for this dynasty." />
          )}
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Past Winners",
      render: () => (
        <Tab.Pane key="pastWinners" className="no-padding">
          {pastWinners.length ? (
            <Table unstackable striped singleLine style={{borderStyle: 'none' }} >
              <Table.Header>
                  <Table.HeaderCell width={5}>Season</Table.HeaderCell>
                  <Table.HeaderCell>Winner</Table.HeaderCell>
              </Table.Header>
              <Table.Body>
                {pastWinners.map((winner, index) => (
                  <Table.Row
                    key={index}
                    className="ranking"
                  >
                    <Table.Cell>{winner.season}</Table.Cell>
                    <Table.Cell>
                      <Flag name={winner.user.country} />
                      {Util.renderUserProfileLink(winner.user.name, winner.user._id)}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
      ) : (
        <Message info content="No winners so far" />
      )}
        </Tab.Pane>
      ),
    },
  ];

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <DynastyCard dynasty={dynasty} />
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  );
};

export default DynastyDetail;
