import { useEffect, useState, createRef } from "react";
import Swal from 'sweetalert2';
import {
  Container, Header, Image, Segment,
  Checkbox, GridColumn, Button,
  Message, Grid, Menu, Icon, Popup, Label, Tab
} from 'semantic-ui-react';
import CompetitionLogos from "../components/Main/CompetitionLogos";

import "../styles/global.css";
import "../styles/leagues.css";

// API
import LeagueAPI from "../api/league";

// Components
import Util from "../util";
import Loading from "../components/Main/Loading";
import Ranking from "../components/Main/Ranking";
import Constants from "../constants/constants";
import MobileShare from "../components/Main/Aux/mobileShare";
import DynastyCard from "../components/Main/DynastyCard";

const Leagues = () => {
  const [league, setLeague] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(0);
  const [isFilterOn, setFilter] = useState(true);
  const shareRef = createRef(null);
  const [previousWeekTabLoading, setPreviousWeekTabLoading] = useState(false);
  const [previousWeekRanking, setPreviousWeekRanking] = useState({});
  const [currentWeekTabLoading, setCurrentWeekTabLoading] = useState(false);
  const [currentWeekRanking, setCurrentWeekRanking] = useState({});

  useEffect(() => {
    async function getLeague(id) {
      try {

        const response = await LeagueAPI.getLeagueInfo(id);

        if (response.success) {
          setLeague(response.data.league);
        } else {
          Util.handleError(response.data);
          setLoading(false);
        }
      } catch(error) {
        // Stop Loading
        setLoading(false);
  
        // Fire errorx
        Util.handleError(error, 'Failed to load league, please reload');
      }
      
    }

    // Reset loader
    setLoading(true);
    setFirstLoad(0);

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');

    if (id) {
      // Get league info
      getLeague(id);
    }

  }, []);

  useEffect(() => {
    if (firstLoad) {
      setLoading(false); // Skip the UseState load
      document.title = league?.name
    } else {
      setFirstLoad(1);
    }
  }, [league]);

  /**
   * Handler called when you want to leave the league
   * @returns {void}
   */
  const handleLeaveLeague = async () => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: `You can always come back and continue where you left off`,
        showCancelButton: true,
        confirmButtonText: 'Leave League',
        confirmButtonColor: 'red',
        cancelButtonColor: 'lightgrey',
      }).then(async (action) => {
        // If the user confirms, we save in the Database
        if (action.isConfirmed) {
          setLoading(true);
          
          try {
            const result = await LeagueAPI.leaveLeague(league._id);

            if (result.success) {
              // Show success message
              Swal.fire({
                title: 'Peace out',
                text: `You have correctly left the league. Remember that you can always join again from the League section of the website`,
                showCancelButton: false,
                confirmButtonText: 'Accept',
                icon: 'success',
              }).then(async () => {
                window.location.href = window.location.origin + Constants.NAV__LEAGUES_URI;
              });
            } else {
              Util.handleError(result.data);
              setLoading(false);
            }
          } catch (error) {
            Util.handleError(error);
            setLoading(false);
          }
        }
      })
    } catch(error) {        
      // Fire error
      Util.handleError(error, 'We couldn\'t process your request. Please try again');
      setLoading(false);
    }
    
  };

  /**
   * Handler called when you want to generate and share the link of a league
   * @returns {void}
   */
  const handleInviteClick = async () => {
    navigator.clipboard.writeText(
      window.location.hostname
      + Constants.NAV__JOIN_LEAGUE_URI
      + league.shareCode
    );
    Util.successMessage(
      null, // No title
      'Link copied to clipboard, start sharing it with your friends!'
    );
  };

  const refreshActiveTab = async (tabIndex) => {
    if (tabIndex === 0) {
      //The overall ranking tab will be loaded by default, so no need to refresh
    }

    // Load Previous week
    if (tabIndex === 1) {
      // If the weekly ranking is already loaded, we just show them
      if (!Object.keys(previousWeekRanking).length) {
        // Load Weekly Ranking
        setPreviousWeekTabLoading(true);

        try {
          const lastWeekRanking = await LeagueAPI.getPreviousWeekRanking(league._id);

          setPreviousWeekRanking(lastWeekRanking.ranking);
          setPreviousWeekTabLoading(false);
        } catch(error) {
          // Stop Loading
          setPreviousWeekTabLoading(false);

          // Fire errorx
          Util.handleError(error, 'Failed to load the weekly stats, please reload');
        }
      }
    }

    // Calculate current league based on current ranking
    if (tabIndex === 2) {
      // If the weekly ranking is already loaded, we just show them
      if (!Object.keys(currentWeekRanking).length) {
        setCurrentWeekTabLoading(true);

        // Calculate current week points based on current ranking
        const auxCurrentWeek = league.ranking.map(points => {

          let weekPoints = 0;
          let weekBets = 0;
          let weekXPoints = 0;

          if(points.previousState) {
            // Calculate weekpoints
            weekPoints = points.points - points.previousState.points;
            weekBets = points.bets - points.previousState.bets;
            weekXPoints = points.xPoints - points.previousState.xPoints;
          }

          return (
            {
                _id: points._id,
                name: points.name,
                country: points.country,
                points: weekPoints,
                ratio: (weekBets > 0) ? (weekPoints / weekBets) : 0 ,
                rankDiff: points.rankDiff,
                xPoints: weekXPoints,
                rank: points.rank,
                isCurrentUser: points.isCurrentUser,
            }
          )
        });

        setCurrentWeekRanking(auxCurrentWeek);
        setCurrentWeekTabLoading(false);
      }
    }
  }

  // Panes
  let panes = [
    {
      menuItem: 'OVERALL',
      render: () => (
        <Tab.Pane
          key='general'
          className='no-padding'
        >
          <Ranking
            mode={Constants.RANKING_MODE__GENERAL}
            showTrends={isFilterOn && !league.isOver}
            users={league.ranking}
            isRankingFromCurrentWeek={league.isRankingFromCurrentWeek}
            maxHeight={400}
            isOver={league.isOver}
          />
        </Tab.Pane>
      )
    },
    {
      menuItem: 'LAST WEEK',
      render: () => (
        <Tab.Pane
          key='previousWeek'
          loading={previousWeekTabLoading}
          className='no-padding'
        >
          <Ranking
            mode={Constants.RANKING_MODE__WEEKLY}
            showTrends={isFilterOn && !league.isOver}
            users={previousWeekRanking}
            maxHeight={400}
            isOver={league.isOver}
          />
        </Tab.Pane>
      ),
    }
  ];

  // If the general ranking is from this week, we can infer the ranking of the week
  if (league.isRankingFromCurrentWeek) {
    panes = [
      ...panes,
      {
        menuItem: {
          icon: { name: 'dot circle', color:'red', className: 'live-icon', size: 'small'}, content:'THIS WEEK'
        },
        render: () => (
          <Tab.Pane
            key='currentWeek'
            loading={currentWeekTabLoading}
            className='no-padding'
          >
            <Ranking
              mode={Constants.RANKING_MODE__WEEKLY}
              showTrends={isFilterOn && !league.isOver}
              users={currentWeekRanking}
              maxHeight={400}
              isOver={league.isOver}
            />
          </Tab.Pane>
        ),
      }
    ]
  }

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <>
        <Container style={{textAlign: '-webkit-center'}}>
          <DynastyCard dynasty={league.dynasty} collapse />
          <div ref={shareRef} >
            <GridColumn style={{ paddingTop: '10px'}}>
              <Grid padded verticalAlign="middle" style={{alignItems: '-webkit-center'}} >
                <Grid.Column width={16} className="no-padding" style={{ height: '50px' }}>
                  <CompetitionLogos minWidth={50} crestUris={league.competitionDetails.reduce((result, competition) => [...result, competition.crestDarkURI], [])} />
                </Grid.Column>
                <Grid.Column width={16}>
                <span style={{ fontSize: "2rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontWeight: "bold" }}>
                  { league.dynasty.season ?? league.name }
                </span>
                </Grid.Column>
              </Grid>
              <Segment
                basic
                textAlign='left'
                className='no-padding no-margin'
                style={{ maxWidth: '600px' }}
              >
                { league.isOver ?
                  (
                    <Message
                      icon='flag checkered'
                      size='tiny'
                      info
                      header='This league is now over. The results you are seeing are definitive'
                    />
                  ) :
                  (
                    <Grid
                      padded='vertically'
                      style={{ paddingTop: '5px' }}
                    >
                      <Grid.Row
                        className='no-padding trends-indent'
                        verticalAlign='middle'
                      >
                        <Grid.Column
                          width={10}
                        >
                          <Checkbox
                            checked={isFilterOn}
                            fitted
                            toggle
                            label='Show trends'
                            onClick={() => setFilter(!isFilterOn)}
                          />
                        </Grid.Column>
                        <Grid.Column
                          width={6}
                          textAlign='right'
                        >
                          { (
                              league.shareCode &&
                              (league.type === Constants.LEAGUE_TYPE__PUBLIC || league.userIsAdmin)
                            ) ?
                            (
                              <Menu
                                icon
                                size='big'
                                compact
                                secondary
                              >
                                <Popup
                                  trigger={
                                    <Menu.Item
                                      onClick={handleInviteClick}
                                    >
                                      <Icon name='add user' className='no-margin'/>
                                    </Menu.Item>
                                  }
                                  on='hover'
                                  content='Invite more people'
                                  size='mini'
                                  position='bottom center'
                                />
                                <MobileShare
                                  sourceRef={shareRef}
                                  className={"no-margin"}
                                />
                              </Menu>
                            ) :
                            (<></>)
                          }
                        </Grid.Column>
                      </Grid.Row>
                    </ Grid>
                  )
                }
              </Segment>
              { league.isOver ?
                ( // If the league is over, we just show the overall ranking
                  <Ranking
                    showTrends={isFilterOn && !league.isOver}
                    users={league.ranking}
                    maxHeight={400}
                    isOver={league.isOver}
                  />
                ) :
                ( // If the league is active, we show general and weekly rankings
                  <Tab
                    menu={{ borderless: true, secondary: true, pointing: true, compact: true }}
                    panes={panes}
                    onTabChange={(event, target) => refreshActiveTab(target.activeIndex)}
                    renderActiveOnly
                  />
                )
              }
            </GridColumn>
          </div>
          <GridColumn style={{maxWidth: '750px', paddingTop: '10px'}}>
            { league.discordCode ?
              (
                <Segment
                  inverted
                  textAlign='left'
                  className='discord-background'
                >
                  <Grid
                    columns={3}
                    verticalAlign='middle'
                    href={`https://discord.gg/${league.discordCode}`}
                    target='_blank'
                  >
                    <Grid.Column
                      width={3}
                      textAlign='center'
                      className='no-side-padding'
                    >
                      <Image
                        spaced
                        size='mini'
                        src={`./img/logo/discord-logo.svg`}
                      />
                    </Grid.Column>
                    <Grid.Column
                      width={8}
                      className='no-side-padding'
                      textAlign='left'
                    >
                      <Header as='h4' inverted>
                        JOIN THE CONVERSATION
                      </Header>
                    </Grid.Column>
                    <Grid.Column
                      width={5}
                      className='no-side-padding'
                      textAlign='center'
                    >
                    <Button
                      inverted
                      size='mini'
                      content='CLICK HERE'
                    />
                    </Grid.Column>
                  </Grid>
                </Segment>
              ) :
              (<></>)
            }
            <Segment
              basic
              textAlign='left'
              className='no-padding'
            >
              <Label
                basic
              >
                🔥
                <Label.Detail>
                  Last Week MVP
                </Label.Detail>
              </Label>
            </Segment>
            <DynastyCard dynasty={league.dynasty} clickDisclaimer />
            <Segment
              basic
              textAlign='left'
              className='no-side-padding'
            >
              <Button
                basic
                color='red'
                onClick={() => handleLeaveLeague()}
              >
                Leave league
              </Button>
            </Segment> 
          </GridColumn>
        </Container>
      </>
    )
    

};

export default Leagues;
