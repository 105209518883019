import { useEffect, useState } from "react";
import {
  Header, Segment
} from 'semantic-ui-react'
import moment from "moment";

// API
import GameAPI from "../api/game";

// Components
import Calendar from "../components/Main/Calendar";
import Loading from "../components/Main/Loading";
import Constants from "../constants/constants";
import Util from "../util";

const Results = () => {
  const [games, setGames] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(0);
  const [nextUpdateTime, setNextUpdateTime] = useState("");

  useEffect(() => {
    async function getGames() {
      try {
        const loadGames = await GameAPI.getRecentGames();
        setGames(loadGames);
      } catch (error) {
        // Stop Loading
        setLoading(false);
  
        // Fire error
        Util.handleError(error, 'Failed to load matches. Please reload');
      }
    }

    try {
      // Reset loader
      document.title = 'Results';
      setLoading(true);
      setFirstLoad(0);

      // Get games
      getGames();
    } catch(error) {
      Util.handleError(error, 'Error getting recent matches. Please retry');
    }
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setLoading(false); // Skip the UseState load

      // Calculate the next refresh time (every out at the 15th minute)
      const currentTime = moment();
      const nextUpdate = moment().hour(currentTime.hour()).minute(Constants.BET_RESULT_REFRESH__MINUTE_OF_HOUR);

      if (currentTime.minute() >= Constants.BET_RESULT_REFRESH__MINUTE_OF_HOUR) {
        nextUpdate.add(1, "hour");
      }

      setNextUpdateTime(nextUpdate.format("MMMM DD, HH:mm"));

    } else {
      setFirstLoad(1);
    }
  }, [games]);

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <>
        <div className="main ui container">
          <Segment basic>
            <Header>The moment of truth</Header>
            <p>
              An overview of your recent predictions and the points you have earned.
            </p>
            <p style={{color: 'grey'}}>
              🕒 Hang tight! This page updates hourly. Next update: {nextUpdateTime}.
            </p>
          </Segment>
          <Calendar
            games={games}
            scope={Constants.NAV__RESULTS_SECTION}
          />
        </div>
      </>
    )
    

};

export default Results;
