import React from "react"
import { Grid, Segment, Image, Label, Icon } from "semantic-ui-react"
import { useNavigate } from "react-router-dom"

const DEFAULT_DYNASTY_LOGO = "https://www.ballkie.com/img/logo/logo_white.png"
const DEFAULT_DYNASTY_RIBBON = '#131200'

const DynastyCard = ({ dynasty, collapse, clickDisclaimer }) => {
  const navigate = useNavigate()
  const ribbonColor = dynasty.color ?? DEFAULT_DYNASTY_RIBBON
  const logo = dynasty.defaultURI ?? dynasty.logoURI ?? DEFAULT_DYNASTY_LOGO

  const isWhiteFriendly = (hexColor) => {
    const color = hexColor.replace("#", "")
    const r = parseInt(color.substring(0, 2), 16)
    const g = parseInt(color.substring(2, 4), 16)
    const b = parseInt(color.substring(4, 6), 16)

    const luminance = 0.299 * r + 0.587 * g + 0.114 * b
    return luminance < 186
  }

  return collapse ? (
    <Segment
      className='no-vertical-padding' style={{ alignItems: "center", backgroundColor: ribbonColor }}
      onClick={() => navigate(`/dynasty?id=${dynasty._id}`)}
    >
      <Grid className="no-margin">
        <Grid.Row columns={2}>
          <Grid.Column width={11} style={{ display: "flex", alignItems: "center" }}>
            <Icon name="chess queen" size="large" style={{ marginRight: 10, color: isWhiteFriendly(ribbonColor) ? 'white' : 'black' }}
            />
            <span style={{ fontSize: "1.2rem", color: isWhiteFriendly(ribbonColor) ? "white" : "black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {dynasty.name}
            </span>
          </Grid.Column>
          <Grid.Column width={5} textAlign="right">
            <Label size="mini" basic className='no-border no-margin no-padding' style={{ backgroundColor: 'transparent', color: isWhiteFriendly(ribbonColor) ? 'white' : 'black' }}>
              SEE MORE
              <Icon name="chevron right" style={{ paddingLeft: '5px'}} />
            </Label>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  ) : (
    <Segment
      className='no-padding' style={{ backgroundColor: ribbonColor}}
      onClick={() => navigate(`/dynasty?id=${dynasty._id}`)}
    >
      <Grid className="no-margin" style={{ alignItems: 'center' }} textAlign='center'>
        <Image
          src={logo}
          size="small"
          style={{ height: "50px", width: "70px", objectFit: "contain", paddingBottom: "2px" }}
        />
        <span style={{ fontSize: "1.2rem", color: isWhiteFriendly(ribbonColor) ? "white" : "black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          {dynasty.name}
        </span>
      </Grid>
      {clickDisclaimer && (
        <span style={{ fontSize: "11px", color: isWhiteFriendly(ribbonColor) ? "white" : "black", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
          This league is part of a dynasty. Click to see more
        </span>
      )}
    </Segment>
  )
}

export default DynastyCard
