import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Swal from 'sweetalert2';
import { Segment, Button, Header, Dropdown, Table } from 'semantic-ui-react';
import Constants from '../../../constants/constants';
import AdminAPI from '../../../api/admin';
import Util from '../../../util';

const AdminTripletEvent = ({ event, filter, filterCompetition }) => {
  const [hidden, setHidden] = useState(false);
  const [results, setResults] = useState(event?.results || []);
  const [loading, setLoading] = useState(false);
  const [loadingInitialize, setLoadingInitialize] = useState(false);
  const [eventStatus, setEventStatus] = useState(event?.status);

  const roundOptions = event.triplet.points.map((point) => ({
    key: point.round,
    text: point.round,
    value: point.round,
  }));

  // Define the Match Status available
  const eventStatusOptions = [
    {
      key: Constants.MATCH_STATUS__SCHEDULED,
      text: Constants.MATCH_STATUS__SCHEDULED.toUpperCase(),
      value: Constants.MATCH_STATUS__SCHEDULED,
    },
    {
      key: Constants.MATCH_STATUS__PLAYING,
      text: Constants.MATCH_STATUS__PLAYING.toUpperCase(),
      value: Constants.MATCH_STATUS__PLAYING,
    },
    {
      key: Constants.MATCH_STATUS__FINISHED,
      text: Constants.MATCH_STATUS__FINISHED.toUpperCase(),
      value: Constants.MATCH_STATUS__FINISHED,
    },
    {
      key: Constants.MATCH_STATUS__POSTPONED,
      text: Constants.MATCH_STATUS__POSTPONED.toUpperCase(),
      value: Constants.MATCH_STATUS__POSTPONED,
    },
    {
      key: Constants.MATCH_STATUS__CANCELLED,
      text: Constants.MATCH_STATUS__CANCELLED.toUpperCase(),
      value: Constants.MATCH_STATUS__CANCELLED,
    },
    {
      key: Constants.MATCH_STATUS__IGNORED,
      text: Constants.MATCH_STATUS__IGNORED.toUpperCase(),
      value: Constants.MATCH_STATUS__IGNORED,
    },
  ]


  useEffect(() => {
    let hidden = true;

    console.log(filter, filterCompetition)

    // Check match status filter
    if (filter === Constants.MATCH_FILTER__FINISHED && event.status === Constants.MATCH_STATUS__FINISHED) {
      hidden = false;
    }

    if (filter === Constants.MATCH_FILTER__PENDING && event.status !== Constants.MATCH_STATUS__FINISHED && moment(event.time).isBefore(new Date())) {
      hidden = false
    }

    if (filter === Constants.MATCH_FILTER__UPCOMING && moment(event.time).isAfter(new Date())) {
      hidden = false
    }

    if (!filter || filter === Constants.MATCH_FILTER__ALL) {
      hidden = false
    }

    // Check competition filter
    if (filterCompetition) {
      if (filterCompetition !== event.competition._id) {
        hidden = true
      }
    }
    
    setHidden(hidden);
  }, [filter, filterCompetition])

  useEffect(() => {
    if (event.results) {
      // Format participant into the dropdown options
      const cleanResults = event.results.map((result) => {
        const participant = event.participants.find(participant => result.id.toString() === participant._id.toString());
        return {
          ...participant,
          result: result?.result,
          updated: false
        }
      })?.sort((a, b) => a.ranking - b.ranking);

      setResults(cleanResults)
    }
  }, [event.results]);

  const handleRoundChange = (playerId, round) => {
    setResults((prevResults) =>
      prevResults.map((player) =>
        player._id === playerId ? { ...player, newResult: round, updated: true } : player
      )
    );
  };

  const initializeResultSet = async () => {
    try {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to reset the result set of ' + event.competition.name + ' - ' + event.name,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#439775',
        cancelButtonColor: 'lightgrey',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoadingInitialize(true);
          try {

            const response = await AdminAPI.initializeResultSetTriplet(event._id);
            
            if (response.success) {
              Swal.fire('Initalized! Refresh to see the new set', '', 'success');
            } else {
              Util.handleError(response.data);
            }
          } catch (error) {
            Util.handleError(error);
          }
          setLoadingInitialize(false);
        }
      });
    } catch (error) {
      Util.handleError(error, 'We couldn\'t save your changes, please retry');
    }
  }

  const handleSave = async () => {
    try {
      let update = '';
      let playerUpdate = '';

      // 1. Check if results has any element with updated = true. If so, generate an array only with those.
      const updatedResults = results.filter(player => player.updated);

      // 2. Generate a string using the previous array
      playerUpdate = updatedResults
        .map(player => `${player.name} [${player.result || 'None'}] --> [${player.newResult}]`)
        .join(', ');


      update = `Updating ${event.competition.name} ${event.name}. Status: ${event.status} to ${eventStatus}. Players: ${playerUpdate}`

      Swal.fire({
        title: 'Are you sure?',
        text: update,
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonColor: '#439775',
        cancelButtonColor: 'lightgrey',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          try {
            const formattedResults = updatedResults.map((player) => ({
              id: player._id,
              result: player.newResult,
            }));

            const response = await AdminAPI.updateEventTriplet(event._id, eventStatus, formattedResults);
            
            if (response.success) {
              Swal.fire('Saved!', '', 'success');
            } else {
              Util.handleError(response.data);
            }
          } catch (error) {
            Util.handleError(error);
          }
          setLoading(false);
        }
      });
    } catch (error) {
      Util.handleError(error, 'We couldn\'t save your changes, please retry');
    }
  };

  return hidden ? null : (
    <>
      <Header attached='top' textAlign='center' className='game-status no-padding' style={{ fontSize: '11px' }}>
        {event.competition.name} {event.name} {moment(event.time).format('DD MMM YYYY HH:mm')}
        {'  [' + event.status.toUpperCase() + '] → '}
            <Dropdown
              inline
              options={eventStatusOptions}
              value={eventStatus}
              onChange={(event, target) => setEventStatus(target.value)}
            />
      </Header>
      <Segment className='no-padding' style={{ fontSize: '11px' }}>
        <Table celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell textAlign='center' colSpan='2'>{event.eventName}</Table.Cell>
            </Table.Row>
            { results.length ?
              results.map((player) => (
                <Table.Row key={player._id} positive={ player.result !== null }>
                  <Table.Cell>{player.name}</Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      placeholder='Select round'
                      options={roundOptions}
                      fluid
                      selection
                      value={player.updated ? player.newResult : player.result}
                      onChange={(event, target) => handleRoundChange(player._id, target.value)}
                    />
                  </Table.Cell>
                </Table.Row>
              )):
              (
                <Table.Row key={1}>
                  <Table.Cell colSpan='2' textAlign="center">
                    Results array not initialised yet
                  </Table.Cell>
                </Table.Row>
              )}
            <Table.Row>
              <Table.Cell colSpan='2'>
                { results.length ?
                  (<Button fluid primary icon='save' onClick={handleSave} loading={loading} />):
                  (<Button fluid color='black' onClick={initializeResultSet} loading={loadingInitialize}>Initialize Result Set </Button>)
                }
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};

export default AdminTripletEvent;
