import { useState, useEffect } from "react";
import { Grid, Image, Button, Segment } from "semantic-ui-react";

import "../../../styles/competitions.css";
import "../../../styles/competitionSelector.css";

import Util from "../../../util";

const CompetitionSelector = ({
  competitions,
  selectedCompetitions,
  setLeagueCompetitions,
  maxCompetitions
}) => {
  const [sports, setSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState("All");

  useEffect(() => {
    // Extract unique sports dynamically and initialize an object to store competitions per sport
    const competitionsObject = competitions.reduce((acc, competition) => {
      const sport = competition.sport.name;
      if (!acc[sport]) {
        acc[sport] = [];
      }
      acc[sport].push(competition);
      return acc;
    }, {});

    // Sort competitions inside each sport group
    Object.keys(competitionsObject).forEach((sport) => {
      competitionsObject[sport].sort(sortCompetitions);
    });

    setSports(competitionsObject);
  }, [competitions]);

  const sortCompetitions = (a, b) => {
    const aSelected = selectedCompetitions.includes(a._id) ? -1 : 1;
    const bSelected = selectedCompetitions.includes(b._id) ? -1 : 1;

    // 1. Prioritize selected competitions
    if (aSelected !== bSelected) {
      return aSelected - bSelected;
    }

    // 2. Prioritize by display number
    if (a.display !== b.display) {
      return a.display - b.display
    }

    // 3. Prioritize competitions with a closer start date (only if both are in the future)
    const now = new Date();
    const aStartDate = new Date(a.startDate);
    const bStartDate = new Date(b.startDate);

    const aIsFuture = aStartDate > now;
    const bIsFuture = bStartDate > now;

    if (aIsFuture && bIsFuture) {
      return aStartDate.getTime() - bStartDate.getTime();
    }

    // 4. Prioritize competitions with an end date further into the future
    const aEndDate = new Date(a.endDate);
    const bEndDate = new Date(b.endDate);

    return bEndDate.getTime() - aEndDate.getTime() || a.sport.name.localeCompare(b.sport.name);
  };

  const selectCompetition = (event, newSelected) => {
    event.preventDefault();

    // If the competition was already selected, remove it
    if (selectedCompetitions.includes(newSelected)) {
      setLeagueCompetitions(selectedCompetitions.filter((comp) => comp !== newSelected));
      return;
    }

    // Check if they've reached the max number of competitions
    if (maxCompetitions && selectedCompetitions.length === maxCompetitions) {
      Util.handleError(
        null,
        "You have reached the max number of competitions per league. Please deselect one before adding more"
      );
      return;
    }

    // Otherwise, add it
    setLeagueCompetitions([...selectedCompetitions, newSelected]);
  };

  const formatCompetitionRows = (array, size) => {
    return  array.reduce((acc, _, index) => {
      if (index % size === 0) acc.push(array.slice(index, index + size));
      return acc;
    }, []);
  };

  return (
    <div>
      {/* Horizontal Tab Filter for Sports */}
      <Segment basic className="sports-tabs">
        <div className="sports-scroll-container">
          <div className="sports-scroll">
            <Button
              className={`sport-button ${selectedSport === "All" ? "active" : ""}`}
              type="button"
              onClick={() => setSelectedSport("All")}
            >
              All
            </Button>
            {Object.keys(sports).map((sport) => (
              <Button
                key={sport}
                className={`sport-button ${selectedSport === sport ? "active" : ""}`}
                type="button"
                onClick={() => setSelectedSport(sport)}
              >
                {sport}
              </Button>
            ))}
          </div>
        </div>
      </Segment>

      {/* Competitions Grid */}
      <div style={{ textAlign: '-webkit-center'}}>
        <Grid centered style={{ maxWidth: '350px' }}>
          {formatCompetitionRows(
              competitions
                .filter((competition) => selectedSport === "All" || selectedSport === competition.sport.name)
                .sort(sortCompetitions),
              3 // Rows of 3 competitions
            ).map((row, index) => (
              <Grid.Row key={'row-' + index} columns={3}>
                {row.map((competition) => (
                  <Grid.Column key={competition._id}>
                  <Button
                    className={`ui button competition ${competition.colors.main}`}
                    id={competition._id}
                    active={selectedCompetitions.includes(competition._id)}
                    onClick={(event) => selectCompetition(event, competition._id)}
                  >
                    <Image
                      className="ui competition img"
                      src={`./img/competitions/${
                        selectedCompetitions.includes(competition._id) ? competition.crestURI : competition.crestDarkURI
                      }.png`}
                    />
                  </Button>
                </Grid.Column>
            ))}
              </Grid.Row>
            ))
          }
        </Grid>
      </div>
    </div>
  );
};

export default CompetitionSelector;
