import axios from 'axios';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const AdminAPI = {
  /**
   * Retrieves started or finished matches
   * @returns {object} An object with 'matches' property - the retrieved matches - and 'success'
   */
   getRecentGames: async () => {
    const res = await axios.get(
      `${apiUrl}/v2/admin/recent`,
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },

  /**
   * Updates the result of a match
   * @param {String} matchId - API id of the match to update
   * @param {String} competitionId - API id of the competition of the match
   * @param {String} status - status of the match
   * @param {Number} localScore - score of the local team
   * @param {Number} awayScore - score of the away team
   * @returns {object} An object with success or fail
   */
  update1X2Result: async (matchId, competitionId, status, localScore, awayScore) => {
    const res = await axios.post(
      `${apiUrl}/admin/update/${matchId}`,
      {
        competitionId,
        status,
        localScore,
        awayScore,
      },
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data;
  },

  /**
     * Updates the result of a podium match
     * @param {String} matchId - API id of the match to update
     * @param {String} competitionId - API id of the competition of the match
     * @param {String} status - status of the podium being updated
     * @param {Object} podiumResult - object with { firstPlace, secondPlace, thirdPlace } 
     * @returns {object} An object with success or fail
     */
  updatePodiumResult: async (matchId, competitionId, status, podiumResult) => {
    const res = await axios.post(
      `${apiUrl}/admin/update/podium/${matchId}`,
      {
        competitionId,
        status,
        podiumResult,
      },
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data;
  },

  /**
     * Updates the result of a podium match
     * @param {String} eventId - id of the event to udpate
     * @param {String} status - status of the event being updated
     * @param {Object} updatedResults - array with elems like: { id, result }
     * @returns {object} An object with success or fail
     */
  updateEventTriplet: async (eventId, status, updatedResults) => {
    const res = await axios.put(
      `${apiUrl}/v2/admin/update/triplet/${eventId}`,
      {
        status,
        updatedResults,
      },
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data;
  },

  /**
     * Updates the result of a podium match
     * @param {String} eventId - id of the event to udpate
     * @returns {object} An object with success or fail
     */
  initializeResultSetTriplet: async (eventId) => {
    const res = await axios.put(
      `${apiUrl}/v2/admin/initialize/triplet/${eventId}`,
      {},
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data;
  },

  /**
   * Retrieves basic info to be able to create matches
   * @returns {object} An object with 'admin' property - the retrieved matches - and 'success'
   */
  getMatchCreationElements: async () => {
    const res = await axios.get(
      `${apiUrl}/admin/creation`,
      {
        headers: Util.generateHeaders(),
      }
    );

    return res.data.data;
  },

    /**
   * Creates a new 1x2 match
   * @param {String} competition - id of the competition
   * @param {String} homeTeam - id of the home team
   * @param {String} awayTeam - id of the away team
   * @param {String} time - date and time of the match
   * @param {boolean} ko - indicates wether the match has ET or not
   * @param {boolean} includesET - if the match has ET, if it needs to be included or not
   * @param {Number} bonusPoints - if applicable, number of bonus points of the match
   * @returns {object} An object with success or fail
   */
    create1X2Match: async (competition, homeTeam, awayTeam, time, ko, includesET, bonusPoints) => {
      const res = await axios.put(
        `${apiUrl}/admin/create/1x2`,
        {
          competition,
          homeTeam,
          awayTeam,
          time,
          ko,
          includesET,
          bonusPoints
        },
        {
          headers: Util.generateHeaders(),
        },
      );
      return res.data;
    },

  /**
   * Creates a new podium match
   * @param {String} competition - id of the competition
   * @param {String} eventName - name of the event
   * @param {String} time - date and time of the match
   * @param {Number} bonusPoints - if applicable, number of bonus points
   * @returns {object} An object with success or fail
   */
  createPodiumMatch: async (competition, eventName, time, bonusPoints) => {
    const res = await axios.put(
      `${apiUrl}/admin/create/podium`,
      {
        competition,
        eventName,
        time,
        bonusPoints
      },
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data;
  },
};

export default AdminAPI;
