import React, {useState, useEffect} from 'react';
import {
  Segment, Grid, GridColumn, Dropdown, Icon
} from 'semantic-ui-react'
import moment from 'moment';


// styles
import "../../../styles/global.css";
import "../../../styles/match.css";
import "../../../styles/competitions.css";

// Components
import MatchStatus from "../GameStatus"
import Constants from "../../../constants/constants";

// API
import BetAPI from "../../../api/bet";
import Util from "../../../util";


const EventTriplet = ({ event, filterBets, filterCompetition, isBetScope }) => {
  const [hidden, setHidden] = useState(false);
  const [currentBet, setBet] = useState({});
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [loadingFirstPlace, setLoadingFirstPlace] = useState(false);
  const [loadingSecondPlace, setLoadingSecondPlace] = useState(false);
  const [loadingThirdPlace, setLoadingThirdPlace] = useState(false);
  const { competition, participants, bet, triplet } = event;
  const { rankLimits } = triplet

  // Format participant into the dropdown options
  const participantsOptions = participants.map((participant) => {
    const ranking = triplet.ranking.find(rank => rank.id.toString() === participant._id.toString());
    return {
      key: participant.name,
      text: participant.name,
      value: participant._id,
      image: { 
        src: `./img/teams/${participant.crestURI ? participant.crestURI : Constants.MATCH_DEFAULT_TEAM__LOGO}.png`,
        avatar: true,
        size: 'huge'
      },
      ranking: ranking?.ranking ?? 999,
      class: 'dropdown-triplet'
    }
  })?.sort((a, b) => a.ranking - b.ranking);

  useEffect(() => {
    if (bet) {
      setBet(bet.podiumBet);
    }

    if (!isBetScope) {
      setDisabledButtons(true);
    }
  }, [bet]);

  useEffect(() => {
    let hidden = false;

    // If the filter per pending bets is enabled, we hide the match
    if (filterBets && currentBet) {
      hidden = true
    } else {
      // If there's no bet or no filter on bets, we filter per competition
      if (filterCompetition) {
        if (filterCompetition !== event.competition._id) {
          hidden = true;
        }
      }
    }

    setHidden(hidden);
  }, [filterBets, filterCompetition])

  /**
   * Set the flag of a loading position for this match
   * @param {String} position - set the indicated position as loading
   * @param {boolean} loading - status of the load
   * @returns {void}
   */
  const setLoadingPosition = (position, loading) => {
    if (position === Constants.MATCH_PODIUM__FIRSTPLACE) {
      setLoadingFirstPlace(loading);
    } else if (position === Constants.MATCH_PODIUM__SECONDPLACE) {
      setLoadingSecondPlace(loading);
    } else {
      setLoadingThirdPlace(loading);
    }
  }

  /**
   * Update a bet object with a new bet
   * @param {Object} initialBet - initial bet object to update
   * @param {String} position - position that has been bet
   * @param {String} bet - bet of the given position
   * @returns {Object} - a new bet object
   */
  const updateBetObject = (initialBet, position, bet) => {
    const updatedBet = initialBet;
  
    if (position === Constants.MATCH_PODIUM__FIRSTPLACE) {
      if (bet) {
        updatedBet.firstPlace = { id: bet };
      } else {
        delete updatedBet.firstPlace;
      }
    } else if (position === Constants.MATCH_PODIUM__SECONDPLACE) {
      if (bet) {
        updatedBet.secondPlace = { id: bet };
      } else {
        delete updatedBet.secondPlace;
      }
    } else {
      if (bet) {
        updatedBet.thirdPlace = { id: bet };
      } else {
        delete updatedBet.thirdPlace;
      }
    }

    return updatedBet;
  }

  /**
   * Sets the new bet based on the reply from the server
   * @param {String} bet - new bet
   * @returns {void}
   */
  const setNewBet = (bet) => {
    if (typeof(bet) === "string") {
      Util.handleError(null, bet);
      return;
    }

    setBet(bet);
  }

  /**
   * Handler called when placing a bet
   * @param {String} position - position of the podium
   * @param {String} teamId - id of the team selected
   * @returns {void}
   */
  const handleBet = async (position, teamId) => {
    try {
      // Disable buttons of the match to prevent re-bets while saving
      setDisabledButtons(true);

      // Set loading until value is saved
      setLoadingPosition(position, true);

      const now = moment().toDate();
      const eventTime = moment(event.time).toDate();

      if(now < eventTime) {

        const newBet = updateBetObject(currentBet, position, teamId);

        // API call to save bet
        const reply = await BetAPI.upsertTripletBet(event, newBet);

        setDisabledButtons(false);

        if (!reply.success) {
          Util.handleError(null, 'We couldn\'t save your bet, please retry');
        } else {
          setNewBet(reply.data);
        }

      } else {
        Util.handleError(null, 'The match is now in progress, we cant save your bet');
      }

      //Stop Loading
      setLoadingPosition(position, false);

    } catch(error) {        
      // Stop Loading
      setLoadingPosition(position, false);

      // Fire error
      Util.handleError(error, 'We couldn\'t save your bet, please retry');
  }
    
  };

  return (
    hidden ?
      (<></>) :
      (
        <>
          <MatchStatus
            status={
              (event.status === Constants.MATCH_STATUS__SCHEDULED && !isBetScope) ?
                Constants.MATCH_STATUS__PLAYING :
                event.status
            }
            time={event.localTime}
            sport={competition?.sport?.icon}
            ko={event.ko}
            bet={currentBet}
            result={event.result}
            points={bet?.points}
            xPoints={null}
            includesET={event.includesET}
            bonusPoints={event.bonusPoints}
            competition={competition}
          />
          <Segment
            attached
            raised
            style={{ border: '0.5px solid' }}
          >
            <Grid>
              <GridColumn
                width={2}
                textAlign='center'
                className={`match-competition active ${competition.colors.main}`}
              >
                <img
                  alt='competition-logo'
                  height={30}
                  src={`./img/competitions/${competition.crestURI}.png`}
                  style={{align:'center'}}
                />
              </GridColumn>
              <GridColumn
                width={14}
              >
                <Grid
                  textAlign='center'
                  className={`reduced-padding active ${competition.colors.main}`}
                >
                  <h4>
                    {event.name}
                  </h4>
                </Grid>
                {/* First Place */}
                <Grid
                  className='match podium border'
                >
                  <GridColumn
                    verticalAlign='middle'
                    textAlign='center'
                    width={3}
                  >
                    Tier 1
                  </GridColumn>
                  <GridColumn
                    width={isBetScope ? 13 : 11}
                  >
                    <Dropdown
                      options={rankLimits?.range1 ?
                        participantsOptions.filter(participant => participant.ranking <= rankLimits.range1)
                        : participantsOptions
                      }
                      disabled={disabledButtons}
                      loading={loadingFirstPlace}
                      value={currentBet?.firstPlace?.id}
                      onChange={(event, target) => handleBet(Constants.MATCH_PODIUM__FIRSTPLACE, target.value)}
                      placeholder='Select a player'
                      fluid
                      floating
                      search
                      selection
                      clearable
                      className='no-side-padding'
                    />
                  </GridColumn>
                  { !isBetScope && (
                    <GridColumn
                      width={2}
                      verticalAlign='middle'
                      className='no-padding'
                    >
                      { event.status === Constants.MATCH_STATUS__FINISHED || bet?.podiumBet?.firstPlace?.points ?
                        (Util.renderEventTripletPoints(bet?.podiumBet?.firstPlace?.points, bet?.podiumBet?.firstPlace?.result)) :
                        event.status !== Constants.MATCH_STATUS__SCHEDULED ?
                          (<Icon name={`${competition?.sport?.icon}`} fitted className='loading'/>):
                          (<></>)
                      }
                    </GridColumn>
                  )}
                </Grid>

                {/* Second Place */}
                <Grid
                  className='match podium border'
                >
                  <GridColumn
                    verticalAlign='middle'
                    textAlign='center'
                    width={3}
                  >
                    Tier 2
                  </GridColumn>
                  <GridColumn
                    width={isBetScope ? 13 : 11}
                  >
                    <Dropdown
                      options={rankLimits?.range1 ?
                        participantsOptions.filter(participant => participant.ranking > rankLimits.range1 && participant.ranking <= rankLimits.range2)
                        : participantsOptions
                      }
                      disabled={disabledButtons}
                      loading={loadingSecondPlace}
                      value={currentBet?.secondPlace?.id}
                      onChange={(event, target) => handleBet(Constants.MATCH_PODIUM__SECONDPLACE, target.value)}
                      placeholder='Select a player'
                      fluid
                      floating
                      search
                      selection
                      clearable
                      className='no-side-padding'
                    />
                  </GridColumn>
                  { !isBetScope && (
                    <GridColumn
                      width={2}
                      verticalAlign='middle'
                      className='no-padding'
                    >
                      { event.status === Constants.MATCH_STATUS__FINISHED || bet?.podiumBet?.secondPlace?.points ?
                        (Util.renderEventTripletPoints(bet?.podiumBet?.secondPlace?.points, bet?.podiumBet?.secondPlace?.result)) :
                        event.status !== Constants.MATCH_STATUS__SCHEDULED ?
                          (<Icon name={`${competition?.sport?.icon}`} fitted className='loading'/>):
                          (<></>)
                      }
                    </GridColumn>
                  )}
                </Grid>

                {/* Third Place */}
                <Grid>
                  <GridColumn
                    verticalAlign='middle'
                    textAlign='center'
                    width={3}
                  >
                    Tier 3
                  </GridColumn>
                  <GridColumn
                    width={isBetScope ? 13 : 11}
                  >
                    <Dropdown
                      options={rankLimits?.range1 ?
                        participantsOptions.filter(participant => participant.ranking > rankLimits.range2)
                        : participantsOptions}
                      disabled={disabledButtons}
                      loading={loadingThirdPlace}
                      value={currentBet?.thirdPlace?.id}
                      onChange={(event, target) => handleBet(Constants.MATCH_PODIUM__THIRDPLACE, target.value)}
                      placeholder='Select a player'
                      fluid
                      floating
                      search
                      selection
                      clearable
                      className='no-side-padding'
                    />
                  </GridColumn>
                  { !isBetScope && (
                    <GridColumn
                      width={2}
                      verticalAlign='middle'
                      className='no-padding'
                    >
                      { event.status === Constants.MATCH_STATUS__FINISHED || bet?.podiumBet?.thirdPlace?.points ?
                        (Util.renderEventTripletPoints(bet?.podiumBet?.thirdPlace?.points, bet?.podiumBet?.thirdPlace?.result)) :
                        event.status !== Constants.MATCH_STATUS__SCHEDULED ?
                          (<Icon name={`${competition?.sport?.icon}`} fitted className='loading'/>):
                          (<></>)
                      }
                    </GridColumn>
                  )}
                </Grid>
              </GridColumn>
            </Grid>
          </Segment>
        </>
      )
  );
};

export default EventTriplet;
