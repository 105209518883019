import { Routes, Route } from "react-router-dom";
import { Container, Divider } from 'semantic-ui-react'

import "../styles/global.css"

import Navigation from "./Nav/Navigation";
import Footer from "./Footer/Footer";
import PrivateRoute from "./Routes/PrivateRoute";

import Main from "../pages/Main";
import Home from "../pages/Home";
import Results from "../pages/Results";
import Welcome from "../pages/Welcome";
import Leagues from "../pages/Leagues";
import LeagueDetail from "../pages/LeagueDetail";
import Unsubscribe from "../pages/Unsubscribe";
import Profile from "../pages/Profile";
import Admin from "../pages/Admin";
import AdminAddMatches from "../pages/AdminAddMatches";
import Privacy from "../pages/Privacy";
import Conditions from "../pages/Conditions";
import Corporate from "../pages/Corporate";
import Settings from "../pages/Settings";
import DynastyDetail from "../pages/DynastyDetail";

function App() {
  return (
    <div style={{ backgroundColor: '#04476E01'}}>
      <div style={{ maxWidth: '600px', justifySelf: 'center'}}>
        <Navigation />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path='/welcome' element={<Welcome />} />
          <Route path='/privacy' element={<Privacy />} />
          <Route path='/conditions' element={<Conditions />} />
          <Route path='/unsubscribe' element={<Unsubscribe />} />
          <Route path='/home' element={<PrivateRoute> <Home /> </PrivateRoute>} />
          <Route path='/results' element={<PrivateRoute> <Results /> </PrivateRoute>} />
          <Route path='/leagues' element={<PrivateRoute> <Leagues /> </PrivateRoute>} />
          <Route path='/league' element={<PrivateRoute> <LeagueDetail /> </PrivateRoute>} />
          <Route path='/user' element={<PrivateRoute> <Profile /> </PrivateRoute>} />
          <Route path='/admin' element={<PrivateRoute> <Admin /> </PrivateRoute>} />
          <Route path='/admin_add' element={<PrivateRoute> <AdminAddMatches /> </PrivateRoute>} />
          <Route path='/corporate' element={<Corporate />} />
          <Route path='/settings' element={<PrivateRoute> <Settings /> </PrivateRoute>} />
          <Route path='/dynasty' element={<PrivateRoute> <DynastyDetail /> </PrivateRoute>} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
