import axios from 'axios';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL;

const DynastyAPI = {
  /**
   * Get the details of a dynasy
   * @param {String} dynastyId - dynasty to look for
   * @returns {object} The info of the dynasty or error
   */
  getDynastyInfo: async (dynastyId) => {
    const res = await axios.get(
      `${apiUrl}/v2/dynasty/${dynastyId}`,
      {
        headers: Util.generateHeaders(),
      },
    );

    return res.data;
  },
};

export default DynastyAPI;
