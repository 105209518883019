import axios from 'axios';
import Util from '../util';

const apiUrl = process.env.REACT_APP_API_URL + '/v2';

const GameAPI = {
  /**
   * Retrieves upcoming games
   * @returns {object} An object with 'games' property - the retrieved matches and events - and 'success'
   */
  getUpcomingGames: async () => {
    const res = await axios.get(
      `${apiUrl}/game/upcoming`,
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },

  /**
   * Retrieves recently finished games
   * @returns {object} An object with 'games' property - the retrieved matches and events - and 'success'
   */
   getRecentGames: async () => {
    const res = await axios.get(
      `${apiUrl}/game/results`,
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },

  /**
   * Retrieves bets from the given user
   * @param {String} userId - user to get the results from
   * @returns {object} An object with 'matches' property - the retrieved matches - and 'success'
   */
  getGamesUser: async (userId) => {
    const res = await axios.get(
      `${apiUrl}/game/bets/${userId}`,
      {
        headers: Util.generateHeaders(),
      },
    );
    return res.data.data;
  },
};

export default GameAPI;
