import React from 'react';

import moment from 'moment';

import "../../styles/gamestatus.css";

import {
  Popup, Icon, Label, Header, Grid, Message
} from 'semantic-ui-react'

import Constants from "../../constants/constants";
import Util from "../../util";

const GameStatus = ({ status, time, sport, ko, points, bet, sameDriverAlert = false, includesET, bonusPoints, competition }) => {


  return (
    <Header
      attached='top' 
      textAlign='center'
      className='game-status no-padding'
    >
      <Grid className='no-side-margin'>
        <Grid.Column width={6} className='game-time'>
          <p style={{ fontWeight: 'lighter', textAlign: 'left', paddingLeft: '10px', textWrapMode: 'nowrap' }}>
            { competition?.name }
          </p>
        </Grid.Column>
        <Grid.Column width={4} className='game-time' >
        { (status === Constants.MATCH_STATUS__SCHEDULED || status === Constants.MATCH_STATUS__POSTPONED) ?
          (
            <>
              { status === Constants.MATCH_STATUS__SCHEDULED ?
                (
                  <>
                    {moment(time).format("HH:mm")}
                  </>
                ) :
                (
                  <Label circular basic size='small' className='no-margin no-border'>
                      <Icon name='calendar times outline' className='no-margin game-saved-bet-icon'/>
                      POSTPONED
                    </Label>
                )
              }
            </>
          ) :
          (
            <>
              { status === Constants.MATCH_STATUS__PLAYING ?
                (
                  <Icon name={`${sport}`} fitted className='loading'/>
                ):
                (
                  <>
                    {Util.renderMatchPoints(points)}
                  </>
                )
              }
            </>
          )
        }
        </Grid.Column>
        { bonusPoints && (
          <Grid.Column width={6} className='multiplier' textAlign='right'>
            <Label color='purple' size='small' className='very-reduced-padding'>
              {'x' + bonusPoints}
            </Label>
            <Label basic color='purple' size='small' className='no-border no-margin no-padding'>
              MULTIPLIER
            </Label>
          </Grid.Column>
        )}
      </Grid>
      { sameDriverAlert ?
        (
          <Message size='tiny' warning className='reduced-padding'>
            <Icon name='exclamation' />
            Points can only be earned once for a driver selected in multiple positions
          </Message>
        ):
        (<></>)
      }
    </Header>
  );
};

export default GameStatus;