import React from "react"
import { Grid, Segment, Image, Statistic } from "semantic-ui-react"

const CompetitionLogos = ({ crestUris, minWidth }) => {
  return (crestUris.length <= 2) ?
    (
      <Grid.Column width={4} basic className='no-padding no-margin' style={{ justifyContent: 'center' }}>
        <Segment basic className='no-padding no-margin' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
        {crestUris.map((uri) => (
          <Segment basic className='no-padding no-margin' style={{ borderWidth: '2px' }}>
            <Image
              style={{
                width: crestUris.length < 3 ? (minWidth ?? 30) : Math.round(70 / crestUris.length) + 'px',
                resizeMode: 'contain',
                padding: '2px'
              }}
              src={`./img/competitions/${uri}.png`}
            />
            </Segment>
          ))
        }
        </Segment>
      </Grid.Column>
    ) :
    (
      <Grid.Column width={4} basic className='no-padding no-margin' style={{ justifyContent: 'center' }}>
        <Segment basic className='no-padding no-margin' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
          <Segment basic className='no-padding no-margin' style={{ borderWidth: '2px' }}>
            <Image
              style={{
                width: minWidth ?? 30 + 'px',
                resizeMode: 'contain',
                padding: '2px'
              }}
              src={`./img/competitions/${crestUris[0]}.png`}
            />
            </Segment>
            <Segment basic className='no-padding no-margin' style={{ borderWidth: '2px' }}>
              <Statistic value={'+' + crestUris.length} size='mini' className='main-text' />
            </Segment>
            </Segment>
      </Grid.Column>
    )
}

export default CompetitionLogos
