import { useEffect, useState } from "react";
import {
  Header, Segment, Icon, Dropdown,
} from 'semantic-ui-react'

// API
import AdminAPI from "../api/admin";

// Components
import Loading from "../components/Main/Loading";
import AdminMatch from "../components/Main/Admin/AdminMatch";
import AdminPodiumMatch from "../components/Main/Admin/AdminPodiumMatch";
import AdminTripletEvent from "../components/Main/Admin/AdminTripletEvent";
import Util from "../util";
import Constants from "../constants/constants";


const Admin = () => {
  const [games, setGames] = useState([]);
  const [orderedGames, setOrderedGames] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(0);
  const [competitionsAvailable, setCompetitionsAvailable] = useState([]);
  const [gameStatusFilter, setGameStatusFilter] = useState(Constants.MATCH_FILTER__ALL);
  const [competitionFilter, setCompetitionFilter] = useState(false);

  const gameStatusFilterOptions = [
    {
      key: 'All',
      text: 'all games',
      value: Constants.MATCH_FILTER__ALL
    },
    {
      key: 'Finished',
      text: 'finished games',
      value: Constants.MATCH_FILTER__FINISHED
    },
    {
      key: 'Pending',
      text: 'pending games',
      value: Constants.MATCH_FILTER__PENDING
    },
    {
      key: 'Upcoming',
      text: 'upcoming games',
      value: Constants.MATCH_FILTER__UPCOMING
    },
  ];

  const allCompetitionsOption = {
    key: 'All',
    text: 'all competitions',
    value: false
  };

  useEffect(() => {
    async function getGames() {
      try {
        const loadGames = await AdminAPI.getRecentGames();
        setGames(loadGames);
      } catch (error) {
        Util.handleError(error);
      }
    }

    try {
      // Reset loader
      setLoading(true);
      setFirstLoad(0);

      // Get matches
      getGames();
    } catch(error) {
      Util.handleError(error, 'Error getting recent matches. Please retry');
    }
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setLoading(false); // Skip the UseState load

      // Get different competitions available
      if (games.length) {
        // Get the matches in local time and order them by that
        const localTimeGames = games.map((game) => ({...game, localTime: (new Date(game.time))}));
        setOrderedGames(localTimeGames.sort((a, b) => new Date(b.localTime) - new Date(a.localTime)));

        // Get unique competitions for the filters
        const uniqueCompetitions = games.reduce((uniqueCompetitions, game) => {
          const competition = game.competition;
          if (!uniqueCompetitions.some(comp => comp.key === competition._id)) {
            uniqueCompetitions.push(
              {
                key: competition._id,
                text: competition.name,
                value: competition._id,
                image: {
                  src: `./img/competitions/${competition.crestDarkURI}.png`,
                  size:'mini',
                  spaced: 'right',
                  className: 'competition-filter-logo'
                },
              }
            );
          }
          return uniqueCompetitions;
        }, []);

        // Adding default option
        setCompetitionsAvailable([
          allCompetitionsOption,
          ...uniqueCompetitions
        ]);

      } else {
        setCompetitionsAvailable([ allCompetitionsOption ]);
      }
      
    } else {
      setFirstLoad(1);
    }
  }, [games]);

  const filters = () => {
    return (
        <>
          <Segment
            basic
            className='no-padding'
            style={{lineHeight: 1.6}}
          >
            <Icon name='filter' />
              Showing {' '}
              <Dropdown
                inline
                options={gameStatusFilterOptions}
                defaultValue={false}
                value={gameStatusFilter}
                onChange={(event, target) => setGameStatusFilter(target.value)}
              />
              {' '} from {' '}
              <Dropdown
                inline
                options={competitionsAvailable}
                defaultValue={false}
                value={competitionFilter}
                onChange={(event, target) => setCompetitionFilter(target.value)}
              />
           <br /><br />            
          </Segment>
        </>
      );
  }

  return isLoading ?
    (
      <Loading/>
    ) :
    (
      <>
        <div className="main ui container">
          <Segment basic>
            <Header>Admin Zone</Header>
            <p>
              From this page you can change the result of the games considered by Ballkie
            </p>
            <p>
              A great power comes with a great responsability. Be careful!
            </p>
          </Segment>
          {filters()}
          { orderedGames && orderedGames.length ?
              orderedGames.map((game) => (
                game?.type === Constants.MATCH_TYPE__1X2 ?
                  (
                    <AdminMatch
                      match={game}
                      filter={gameStatusFilter}
                      filterCompetition={competitionFilter}
                    />
                  ):
                  game?.type === Constants.MATCH_TYPE__PODIUM ?
                    (
                      <AdminPodiumMatch
                        match={game}
                        filter={gameStatusFilter}
                        filterCompetition={competitionFilter}
                      />
                    ):
                    game?.type === Constants.EVENT_TYPE__TRIPLET ?
                    (
                      <AdminTripletEvent
                        event={game}
                        filter={gameStatusFilter}
                        filterCompetition={competitionFilter}
                      />
                    ):
                    (<></>)
              )) :
              (
                <p>
                  No matches available
                </p>
              )

          }
        </div>
      </>
    )
    

};

export default Admin;
